import React from 'react';

import ListadoArticulos from './listadoArticulos';
import ListadoAhorro from './listadoAhorro';
import ListadoTrans from './listadoTrans';
import ListadoVital from './listadoVital';


const LlamadoListados = ({nombre}) => {
        //console.log(nombre);
    switch (nombre) {
        case "Publicaciones":
            return <ListadoArticulos />;
        case "Energía Ahorro":
            return <ListadoAhorro />;   
        case "Energía Transformación":
            return <ListadoTrans />;
        case "Energía Vital":
            return <ListadoVital />;
        default:
            return null;
    }       
};

export default LlamadoListados;