import React, {useState} from 'react';
import { css } from "@emotion/react";

import useArticulos from '../hooks/useArticulos';
import ArticuloPreview from './articuloPreview';
import ListadoArticulosCSS from '../css/listadoArticulos.module.css';

const ListadoVital = () => {

    const resultado = useArticulos();
    const [articulos] = useState(resultado);
    
    const filtrados = articulos.filter( articulo => articulo.categoria.nombre === "Energía vital");
    
    return (
        <>  
        <h2 css={css`
            margin-top: 5rem;
        `}>Atículos Publicados Sobre Ahorro de Energía</h2>
        
        <ul className={ListadoArticulosCSS.articulos}>
            {filtrados.map(articulo => (
                <ArticuloPreview 
                    key={articulo.id}
                    articulo={articulo}
                />
            ))}
        </ul>
        </>
    );
}
 
export default ListadoVital;