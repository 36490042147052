import React, {useState, useEffect} from 'react';
import { css } from "@emotion/react";

import useArticulos from '../hooks/useArticulos';
import useFiltro from '../hooks/useFiltro';
import ArticuloPreview from './articuloPreview';
import ListadoArticulosCSS from '../css/listadoArticulos.module.css';

const ListadoArticulos = () => {

    const resultado = useArticulos();
    const [articulos] = useState(resultado);
    const [filtrados, setFiltrados] = useState([]);
    
    //filtrado de articulos
    const { categoria, FiltroUI } = useFiltro();

    
    useEffect(() => {
        if (categoria) {
            const filtro = articulos.filter( articulo => articulo.categoria.nombre === categoria);
            setFiltrados(filtro);
        } else {
            setFiltrados(articulos);
        }
        //setArticulos(resultado);        
    }, [categoria, articulos]);

    //console.log(articulos);

    return (
        <>  
        <h2 css={css`
            margin-top: 5rem;
        `}>Atículos Publicados</h2>
        {FiltroUI()}
        <ul className={ListadoArticulosCSS.articulos}>
            {filtrados.map(articulo => (
                <ArticuloPreview 
                    key={articulo.id}
                    articulo={articulo}
                />
            ))}
        </ul>
        </>
    );
}
 
export default ListadoArticulos;